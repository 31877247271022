<template>
  <div class="review">
    <AnnotationContext></AnnotationContext>
    <!-- <kendo-splitter
    class="review-main"
        :panes="vSplitter1.panes"
        :orientation="vSplitter1.orientation"
        :scrollable="vSplitter1.scrollable">
        <kendo-splitter
          :refs="'vSplitter1'"
          :panes="hSplitter1.panes"
          :orientation="hSplitter1.orientation"
          :scrollable="hSplitter1.scrollable">
          <SplitterContainer title="Publication" component="ActionsManageStagesForms" detach="true"/>
          <SplitterContainer title="Citation" component="Citation"/>
          <SplitterContainer title="Abstract" component="Abstract"/>
        </kendo-splitter>
        <kendo-splitter
          :refs="'hSplitter2'"
          :panes="hSplitter2.panes"
          :orientation="hSplitter2.orientation"
          :scrollable="hSplitter2.scrollable">
          <SplitterContainer title="Annotations" component="Annotations"/>
          <SplitterContainer title="Forms" component="Forms" detach="true"/>
          <SplitterContainer title="Full Text" component="FullText" detach="true"/>
        </kendo-splitter>
  </kendo-splitter> -->
  <kendo-splitter
    class="review-main"
    :panes="hSplitter1.panes"
    :orientation="hSplitter1.orientation"
    :scrollable="hSplitter1.scrollable">
    <kendo-splitter
      :panes="vSplitter1.panes"
      :orientation="vSplitter1.orientation"
      :scrollable="vSplitter1.scrollable">
      <SplitterContainer title="Decision (ID: 1234) - Progress 1 of 1 (100%)" component="ActionsManageStagesForms" :swap="true" :swaptitle="'Swap'" :filter="true" :filtertitle="'Citations List'" :info="true" :infotitle="'Help & Shortcuts'" :detach="false"/>
      <SplitterContainer title="Annotations" component="Annotations"/>
    </kendo-splitter>
    <kendo-splitter
      :panes="vSplitter2.panes"
      :orientation="vSplitter2.orientation"
      :scrollable="vSplitter2.scrollable">
      <kendo-splitter
        :panes="hSplitter2.panes"
        :orientation="hSplitter2.orientation"
        :scrollable="hSplitter2.scrollable">
        <SplitterContainer title="Citation" component="Citation" editable="true"/>
        <SplitterContainer title="Abstract" component="Abstract" editable="true"/>
      </kendo-splitter>
      <kendo-splitter
        :panes="hSplitter3.panes"
        :orientation="hSplitter3.orientation"
        :scrollable="hSplitter3.scrollable">
        <SplitterContainer title="Forms" component="Forms" :detach="true" :detachtitle="'Open Forms view in new window'" :info="true" :infotitle="'Help & Shortcuts'"/>
        <SplitterContainer title="Full Text" component="FullText" :swap="false" :detach="true" :detachtitle="'Open Full Text Review in new window'"/>
      </kendo-splitter>
    </kendo-splitter>
  </kendo-splitter>
  </div>
</template>

<script>
import SplitterContainer from '@/containers/SplitterContainer'
import AnnotationContext from '@/containers/AnnotationContext'

export default {
  name: 'review',
  components: {
    SplitterContainer,
    AnnotationContext
  },
  data () {
    return {
      highlightedtext: '',
      currentFocus: '',
      isProcess: false,
      vSplitter1old: {
        scrollable: true,
        orientation: 'vertical',
        panes: [
          { collapsible: false, size: '50%' },
          { collapsible: true, size: '50%' }
        ]
      },
      hSplitter1old: {
        scrollable: true,
        orientation: 'horizontal',
        panes: [
          { collapsible: true, size: '30%' },
          { collapsible: true, size: '30%' },
          { collapsible: true, size: '30%' }
        ]
      },
      hSplitter2old: {
        scrollable: true,
        orientation: 'horizonal',
        panes: [
          { collapsible: true, size: '30%' },
          { collapsible: true, size: '30%' },
          { collapsible: true, size: '30%' }
        ]
      },
      hSplitter1: {
        scrollable: true,
        orientation: 'horizontal',
        panes: [
          { collapsible: false, size: '30%' },
          { collapsible: true, size: '70%' }
        ]
      },
      vSplitter1: {
        scrollable: true,
        orientation: 'vertical',
        panes: [
          { collapsible: false, size: '50%' },
          { collapsible: true, size: '50%' }
        ]
      },
      vSplitter2: {
        scrollable: true,
        orientation: 'vertical',
        panes: [
          { collapsible: true, size: '50%' },
          { collapsible: true, size: '50%' }
        ]
      },
      hSplitter2: {
        scrollable: true,
        orientation: 'horizontal',
        panes: [
          { collapsible: false, size: '50%' },
          { collapsible: true, size: '50%' }
        ]
      },
      hSplitter3: {
        scrollable: true,
        orientation: 'horizontal',
        panes: [
          { collapsible: false, size: '50%' },
          { collapsible: true, size: '50%' }
        ]
      }
    }
  },
  mounted: function () {
    this.$store.state.selectedNavigation = 'process'
    this.$store.state.selectedAction = 'manage-forms'
    this.$store.state.showActions = true
  }
}
</script>
